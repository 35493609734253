<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent title="사용자 목록" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn class="mx-0" color="primary" @click="goToAdd">
          <v-icon>add</v-icon>
          <span class="pl-2">사용자 추가</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table class="tbl elevation-2">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-for="item in headers" :key="item.text" :class="`text-${item.align}`">
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
              :enable-app-selector="true"
            />
            <tbody v-if="!isLoading">
              <tr v-for="item in items" :key="`service-tos-tr-${item.idx}`">
                <td class="text-center">
                  <router-link :to="`/admin/users/${item.idx}`">
                    {{ item.id }}
                  </router-link>
                </td>
                <td class="text-center">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        >{{ item.email }}
                        <!-- <v-icon v-if="item.mail_verified === true" dense color="green">
                          mdi-check-circle-outline
                        </v-icon>
                        <v-icon v-else dense color="warning">
                          mdi-alert-circle-outline
                        </v-icon> -->
                      </span>
                    </template>
                    <!-- <span v-if="item.mail_verified === true">메일 인증됨</span>
                    <span v-else>인증되지 않은 메일</span> -->
                  </v-tooltip>
                  <br />
                </td>
                <td class="text-center">
                  <span class="caption">
                    {{ item.username }}
                  </span>
                </td>
                <td class="text-center">
                  <span class="caption">
                    {{ item.role }}
                  </span>
                </td>
                <td class="text-center">
                  {{ item.createdAt | dayjsLocalDatetime }}
                </td>
                <td class="text-center">
                  <button @click="deleteUser(item.id, item.role)" class="del_btn">삭제</button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent v-model="pagination" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import paginationLib from '../_elements/pagination/paginationLib'
import PaginationComponent from '../_elements/pagination/Pagination'
import Api from './model/userApi'
import Config from '../../config'
import axios from 'axios'

export default {
  components: {
    TbodyPreRender,
    PageTitleComponent,
    PaginationComponent
  },
  data() {
    return {
      isLoading: false,
      query: '',
      items: [],
      pagination: {},
      headers: [
        { text: 'User ID', align: 'center' },
        { text: 'Mail', align: 'center' },
        { text: 'User Name', align: 'center' },
        { text: 'Role', align: 'center' },
        { text: 'Created at', align: 'center' },
        { text: 'Edit', align: 'center' }
      ]
    }
  },
  created() {
    const params = this.$route.query
    if (params.page === undefined) {
      this.$router.push({ query: { page: 1 } }, () => {})
    } else {
      this.query = params.query || ''
      this.pagination.page = parseInt(params.page, 10) || 1
      this.getItems()
    }
  },
  methods: {
    async getItems() {
      this.isLoading = true
      const result = await axios.get(`${Config.Api.Service2.url}/admin`)
      this.items = result.data
      this.isLoading = false
    },
    goToAdd() {
      const isAdmin = this.$store.getters['Account/getUser'].role
      if (isAdmin === 'admin') this.$router.push(`/admin/users/add`)
      else this.$bus.$emit('errorMessage', '권한이 없습니다.')
    },
    async deleteUser(id, role) {
      const isAdmin = this.$store.getters['Account/getUser'].role
      if (isAdmin !== 'admin' || role === 'admin') return this.$bus.$emit('errorMessage', '권한이 없습니다.')

      const result = await axios.delete(`${Config.Api.Service2.url}/admin/${id}`)

      if (result.status === 200) {
        this.items = this.items.filter(e => e.id !== id)
        this.$bus.$emit('successMessage', '관리자가 삭제되었습니다.')
      } else {
        this.$bus.$emit('errorMessage', '서버 통신에 문제가 발생했습니다')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    // table-layout: fixed;
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}

.del_btn {
  color: red;

  &:hover {
    text-decoration: underline;
  }
}
</style>
